import loadable from "@loadable/component";
import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Heading2 } from "../../../components/typography";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeUsecases() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  return (
    <Container style={{ maxWidth: 1120 }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Grid
        style={{
          marginTop: 32,
          maxWidth: 1120,
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "#eefbf9",
          // border: "1.5px solid #E2FCF8",
          borderRadius: 32,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          paddingBottom: 62,
          paddingRight: 16,

          // backgroundImage: matches ? "url(/images/cta.webp)" : "url(/images/cta.webp)",
        }}>
        <Heading2
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 62,
            paddingLeft: matches ? 62 : 40,
            paddingRight: matches ? 62 : 40,
            marginBottom: 32,
            maxWidth: 610,
            textAlign: "center",
            ...(matches ? {} : { fontSize: 24 }),
          }}>
          Three Sample Use Cases for EmailWebhook
        </Heading2>

        <ol
          style={{
            color: "#000",
            fontSize: 16,
            maxWidth: 600,
            marginLeft: "auto",
            marginRight: "auto",
          }}>
          <li>
            <h3>Automated Lead Qualification</h3>
            <p>
              Use EmailWebhook to create unique email addresses for different lead generation
              campaigns. When potential leads send inquiries to these addresses, the emails can be
              automatically parsed and categorized based on lead quality criteria. This helps sales
              teams prioritize their follow-ups and improve lead conversion rates.
            </p>
          </li>
          <li>
            <h3>Customer Support Ticketing</h3>
            <p>
              Implement EmailWebhook to streamline your customer support process. When customers
              send emails to a designated support address, EmailWebhook can create support tickets
              in your system, categorize them by issue type, and assign them to the appropriate
              support agents. This ensures timely and efficient support for your customers.
            </p>
          </li>
          <li>
            <h3>Data Extraction and Processing</h3>
            <p>
              Utilize EmailWebhook to extract specific data from incoming emails, such as order
              information or survey responses. This data can be automatically processed and
              integrated into your business applications, allowing for real-time updates to your
              inventory, CRM, or data analytics systems, ultimately improving data accuracy and
              workflow efficiency.
            </p>
          </li>
        </ol>

        <div
          style={{
            color: "#000",
            maxWidth: 560,
            marginTop: 30,
            padding: 16,
            marginLeft: "auto",
            textAlign: "center",
            marginRight: "auto",
          }}>
          These use cases demonstrate how EmailWebhook can be applied to automate various aspects of
          email management and integration, offering efficiency and scalability to different
          business processes.
        </div>
      </Grid>
    </Container>
  );
}
