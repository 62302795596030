import { action, observable } from "mobx";

const editDocStore = observable({
  id: "",
  title: "",
  isOpen: false,
  open: action((id, title) => {
    editDocStore.id = id;
    editDocStore.title = title;
    editDocStore.isOpen = true;
  }),
  close: action(() => {
    editDocStore.id = "";
    editDocStore.title = "";
    editDocStore.isOpen = false;
  }),
});

export default editDocStore;
