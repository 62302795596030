import firebase from "firebase/compat/app";
const config = {
  apiKey: "AIzaSyCVZSoY0LQqy4Iqsf3TiuFXAqs5t1W9GgI",
  authDomain: "emailwebhookcom.firebaseapp.com",
  projectId: "emailwebhookcom",
  storageBucket: "emailwebhookcom.appspot.com",
  messagingSenderId: "837715819303",
  appId: "1:837715819303:web:e938352b9545fea1584702",
  measurementId: "G-NJ5KVS3LHQ",
};

firebase.initializeApp(config);

export default firebase;
