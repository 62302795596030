import React from "react";
import Bar from "../../components/Bar/Bar";
import FooterView from "../../components/FooterView";
import HomeHeader from "./ui/HomeHeader";
import HomeHow from "./ui/HomeHow";

import loadable from "@loadable/component";
import HomeTryIt from "./ui/HomeTryIt";
import HomeUsecases from "./ui/HomeUsecases";
const ContentView = loadable(() => import("./ContentView"));

export default function HomeScreen() {
  return (
    <div style={{ background: "#fff" }}>
      <Bar />
      <HomeHeader />
      <HomeTryIt />
      <HomeHow />
      <HomeUsecases />
      <ContentView />

      <FooterView />
    </div>
  );
}
