import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyMedium, Heading1 } from "../../../components/typography";

import loadable from "@loadable/component";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = smAndUp ? {} : { fontSize: 35, lineHeight: "40px" };
  const textStyle = smAndUp ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{ backgroundColor: "#EEFBF9" }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          paddingTop: smAndUp ? 250 : 100,
          paddingBottom: smAndUp ? 120 : 20,
          textAlign: "center",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          // backgroundImage: smAndUp ? "url(/images/sample-bg.webp)" : "url(/images/sample-bg.webp)",
        }}>
        <Heading1
          style={{
            maxWidth: 900,
            color: "#16181A",
            marginLeft: "auto",
            lineHeight: "80px",
            marginRight: "auto",
            fontWeight: "bold",
            textAlign: "center",
            ...headerStyle,
          }}>
          The hassle-free email integration solution built for the AI era
          {
            // Receive Emails as JSON in Your App via Webhooks
          }
        </Heading1>

        <BodyMedium
          style={{
            fontSize: 22,
            maxWidth: 560,
            color: "#000",
            marginBottom: 20,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: smAndUp ? 27 : 10,
            ...textStyle,
          }}>
          Receive Emails as JSON in Your App via Webhooks to Automate and Optimize.
        </BodyMedium>

        {false && (
          <Button
            color="secondary"
            variant="contained"
            onClick={() => setShowSignUpDialog(true)}
            style={{ marginRight: 8, marginBottom: 40, height: 46, color: "#fff", width: 160 }}>
            Sign up free
          </Button>
        )}

        {false && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setShowSignUpDialog(true)}
            style={{ marginLeft: 8, height: 46, marginBottom: 40, width: 160 }}>
            Learn more
          </Button>
        )}

        {false && (
          <img
            src="images/sample.webp"
            style={{ width: smAndUp ? "50%" : "100%", marginTop: smAndUp ? 60 : 40 }}
            alt="RV"
          />
        )}
      </Container>
    </div>
  );
}
