import { Button, Container } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { BodyMedium, Heading1 } from "../../../components/typography";

import loadable from "@loadable/component";

const SignUpDialog = loadable(() => import("../../login/SignUpDialog/SignUpDialog"));

export default function HomeHeader() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);

  const headerStyle = matches ? {} : { fontSize: 35, lineHeight: "40px" };
  const textStyle = matches ? {} : { fontSize: 14, maxWidth: 310 };

  return (
    <div style={{ backgroundColor: "#0B4B76" }}>
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}

      <Container
        style={{
          maxWidth: 1440,
          paddingTop: matches ? 175 : 100,
          paddingBottom: matches ? 120 : 20,
          textAlign: "center",
          backgroundSize: "contain",
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          // backgroundImage: matches ? "url(/images/sample-bg.webp)" : "url(/images/sample-bg.webp)",
        }}>
        <Heading1
          style={{
            maxWidth: 1200,
            color: "#fff",
            marginLeft: "auto",
            lineHeight: "80px",
            marginRight: "auto",
            fontWeight: "bold",
            textAlign: "center",
            ...headerStyle,
          }}>
          Latest Articles
        </Heading1>

        {false && (
          <Button
            color="secondary"
            variant="outlined"
            onClick={() => setShowSignUpDialog(true)}
            style={{ marginLeft: 8, height: 46, marginBottom: 40, width: 160 }}>
            Learn more
          </Button>
        )}

        {false && (
          <img
            src="images/sample.webp"
            style={{ width: matches ? "50%" : "100%", marginTop: matches ? 60 : 40 }}
            alt="RV"
          />
        )}
      </Container>
    </div>
  );
}
