/* eslint-disable no-constant-condition */
import { Container, useMediaQuery } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CardMembershipIcon from "@material-ui/icons/CreditCard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PersonIcon from "@material-ui/icons/Person";
import { observer } from "mobx-react";
import React from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import appStore from "../../app/store/appStore";
import { showError, snackbarStore } from "../SnackbarHelper/SnackbarHelper";

import loadable from "@loadable/component";
import auth from "../../services/firebase/auth";
import editDocStore from "../../features/docs/editDocStore";

const SettingsDialog = loadable(() =>
  import("../../features/settings/SettingsDialog/SettingsDialog")
);

const SignInDialog = loadable(() => import("../../features/login/SignInDialog/SignInDialog"));
const SignUpDialog = loadable(() => import("../../features/login/SignUpDialog/SignUpDialog"));

export function HashLink({ children, style, ...props }) {
  return (
    <a
      {...props}
      style={{
        fontSize: 16,
        color: "#555",
        paddingLeft: 24,
        paddingRight: 24,
        cursor: "pointer",
        fontFamily: "Barlow",
        textDecoration: "none",
        ...style,
      }}>
      {children}
    </a>
  );
}

function Bar(props) {
  const history = useHistory();
  const location = useLocation();

  const { working, user, userData } = appStore;

  const { hideLinks, canCreate } = props;
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const matchesMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [showLoginDialog, setShowLoginDialog] = React.useState(false);
  const [showSignUpDialog, setShowSignUpDialog] = React.useState(false);
  const [showSettingsDialog, setShowSettingsDialog] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function signOut() {
    return new Promise((resolve, reject) => {
      const currentUser = auth.currentUser;

      if (!currentUser) {
        reject();

        return;
      }

      auth
        .signOut()
        .then(value => {
          resolve(value);
        })
        .catch(reason => {
          reject(reason);
        });
    });
  }

  const drawerWidth = user ? 240 : 0;

  const useStyles = makeStyles(theme => ({
    root: {
      display: "flex",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      height: matches ? 85 : undefined,
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      borderBottom: "1.5px solid #F4F5F6",
      /*[theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },*/
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }));

  const classes = useStyles();

  return (
    <>
      <AppBar elevation={0} position="fixed" className={classes.appBar}>
        <Container style={{ maxWidth: 1440 }}>
          <Toolbar
            style={{
              marginTop: matches ? 10 : 0,
              paddingLeft: 0,
              paddingRight: 0,
              maxWidth: 1440,
            }}>
            {user && location.pathname === "/pricing" && (
              <Button
                color="primary"
                component="span"
                startIcon={<ArrowBackIcon />}
                onClick={() => history.push("/")}>
                Back to Dashboard
              </Button>
            )}

            {(location.pathname !== "/pricing" || !user) && (
              <Box display="flex" flexGrow={1} style={{ marginLeft: 8 }}>
                <img
                  src="/logo192.webp"
                  style={{ width: 32, height: 32, marginRight: 4, marginLeft: matches ? 0 : -16 }}
                  alt="RV"
                />

                <Typography
                  style={{
                    fontSize: matches ? 24 : 18,
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontFamily: "Barlow",
                    textDecoration: "none",
                  }}
                  color="inherit"
                  component={Link}
                  to="/">
                  EmailWebhook {false && <span style={{ fontSize: 10 }}>Beta</span>}
                </Typography>
              </Box>
            )}

            {!hideLinks && matchesMdUp && !user && location.pathname !== "/pricing" ? (
              <Box display="flex" flexGrow={1}>
                {false && <HashLink href="#faq">FAQ</HashLink>}
                {false && <HashLink href="/pricing">Pricing</HashLink>}
                {false && <HashLink href="/blog">Blog</HashLink>}
              </Box>
            ) : (
              <Box display="flex" flexGrow={1}></Box>
            )}

            {!user && (
              <div color="inherit" disabled={working}>
                {!matchesMdUp && false ? (
                  <Button size="small" variant="text" href="/pricing">
                    Pricing
                  </Button>
                ) : null}

                <Button size="small" variant="contained" color="primary" href="#request">
                  Get Early Access
                </Button>

                {false && (
                  <Button
                    size="small"
                    variant="text"
                    style={{ marginRight: 8 }}
                    onClick={() => setShowLoginDialog(true)}>
                    Login
                  </Button>
                )}

                {false && (
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => setShowSignUpDialog(true)}>
                    Sign up free
                  </Button>
                )}
              </div>
            )}

            {canCreate ? (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => editDocStore.open()}
                size={matches ? undefined : "small"}
                style={{ color: "#fff", maxWidth: 120 }}>
                <AddIcon style={{ fontSize: matches ? undefined : 20 }} />
                Create
              </Button>
            ) : null}

            {user && (
              <div>
                {user && (
                  <IconButton style={{ marginLeft: 8 }} onClick={handleMenu} color="inherit">
                    <AccountCircle />
                  </IconButton>
                )}

                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={handleClose}>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      setShowSettingsDialog(true);
                    }}>
                    <ListItemIcon>
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText primary={user ? (userData || {}).name || "Account" : "Account"} />
                  </MenuItem>

                  <MenuItem component={Link} to={"/pricing"}>
                    <ListItemIcon>
                      <CardMembershipIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Subscriptions"} />
                  </MenuItem>

                  <MenuItem
                    onClick={() => {
                      signOut()
                        .then(() => snackbarStore.open("Signed out"))
                        .then(() => handleClose)
                        .catch(reason => showError(reason))
                        .finally(() => appStore.reset());
                    }}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary={"Sign out"} />
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      {showLoginDialog && <SignInDialog onClose={() => setShowLoginDialog(false)} />}
      {showSignUpDialog && <SignUpDialog onClose={() => setShowSignUpDialog(false)} />}
      {showSettingsDialog && <SettingsDialog onClose={() => setShowSettingsDialog(false)} />}
    </>
  );
}

export default observer(Bar);
