import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { withStyles } from "@material-ui/core/styles";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React, { Component } from "react";

export const alertStore = observable({
  isOpen: false,
  open: action(() => (alertStore.isOpen = true)),
  close: action(() => (alertStore.isOpen = false)),
});

class AlertDialog extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    // Custom Properties
    const {
      open,
      title,
      contentText,
      dismissiveAction,
      confirmingAction,
      acknowledgementAction,
    } = this.props;

    if ((dismissiveAction || confirmingAction) && acknowledgementAction) {
      console.error(
        "Dialogs should contain a maximum of two actions. " +
          "If a single action is provided, it must be an acknowledgement action. " +
          "If two actions are provided, one must be a confirming action, and the other a dismissing action. " +
          "Providing a third action such as “Learn more” is not recommended as it navigates the user away from the dialog, leaving the dialog task unfinished. " +
          "https://material.io/design/components/dialogs.html#actions"
      );

      return null;
    }

    return (
      <Dialog open={open}>
        {title && <DialogTitle>{title}</DialogTitle>}

        <DialogContent className={title ? null : classes.noTitlePadding}>
          <DialogContentText>{contentText}</DialogContentText>
        </DialogContent>

        {(dismissiveAction || confirmingAction || acknowledgementAction) && (
          <DialogActions>
            {dismissiveAction}
            {confirmingAction}
            {acknowledgementAction}
          </DialogActions>
        )}
      </Dialog>
    );
  }
}

const styles = theme => ({
  noTitlePadding: {
    paddingTop: theme.spacing(3),
  },
});

export default withStyles(styles)(observer(AlertDialog));
