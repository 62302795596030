import React from "react";
import { v4 as uuidv4 } from "uuid";

export function getViewerId() {
  let viewerId = getStorageValue("viewerId");
  if (!viewerId) {
    viewerId = uuidv4();
    setStorageValue("viewerId", viewerId);
  }

  return viewerId;
}

export function getStorageValue(key, defaultValue) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = JSON.parse(saved);
  return initial || defaultValue;
}

export function setStorageValue(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = React.useState(() => {
    return getStorageValue(key, defaultValue);
  });

  React.useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
