import Snackbar from "@material-ui/core/Snackbar";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import readingTime from "reading-time";

const AUTO_HIDE_DURATION = 2;

export const snackbarStore = observable({
  isOpen: false,

  open: action("open snackbar", message => {
    snackbarStore.isOpen = true;
    snackbarStore.autoHideDuration = readingTime(message).time * AUTO_HIDE_DURATION;
    snackbarStore.message = message;
  }),

  close: action("close snackbar", () => {
    snackbarStore.isOpen = false;
    snackbarStore.message = "";
  }),
});

export const showError = error => {
  console.log("error: ", error);
  if (error && error.message) {
    snackbarStore.open(error.message);
  } else {
    snackbarStore.open("Something went wrong!");
  }
};

const SnackbarHelper = () => (
  <Snackbar
    open={snackbarStore.isOpen}
    onClose={snackbarStore.close}
    message={snackbarStore.message}
    autoHideDuration={snackbarStore.autoHideDuration}
  />
);

export default observer(SnackbarHelper);
