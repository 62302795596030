export default {
  Gray: {
    G50: "#F9FAFA",
    G100: "#F4F5F6",
    G200: "#E9EBEC",
    G300: "#C8CCD0",
    G400: "#9CA3AB",
    G500: "#74828B",
    G600: "#5D676F",
    G700: "#3B3B4F",
    G800: "#2F3337",
    G900: "#16181A",
  },
};
