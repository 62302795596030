import React, { Component } from "react";

import ErrorIcon from "@material-ui/icons/Error";

import EmptyState from "../EmptyState";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {}

  render() {
    // Properties
    const { children } = this.props;

    const { hasError } = this.state;

    if (hasError) {
      return <EmptyState icon={<ErrorIcon />} title="Something went wrong" />;
    }

    return children;
  }
}

export default ErrorBoundary;
