import React from "react";

export function Heading1({ style, children }) {
  return (
    <div
      style={{
        fontFamily: "Barlow",
        fontWeight: "bold",
        fontSize: "70px",
        fontFeatureSettings: "'salt' on",
        ...style,
      }}>
      {children}
    </div>
  );
}

export function Heading2({ style, children }) {
  return <Heading1 style={{ fontSize: 40, ...style }}>{children}</Heading1>;
}

export function Heading3({ style, children }) {
  return <Heading1 style={{ fontSize: 40, ...style }}>{children}</Heading1>;
}

export function Heading4({ style, children }) {
  return <Heading1 style={{ fontSize: 32, ...style }}>{children}</Heading1>;
}

export function Heading5({ style, children }) {
  return <Heading1 style={{ fontSize: 24, ...style }}>{children}</Heading1>;
}

export function BodySmall({ style, children }) {
  return (
    <div
      style={{
        fontFamily: "Barlow",
        fontFeatureSettings: "'salt' on",
        fontSize: 14,
        ...style,
      }}>
      {children}
    </div>
  );
}

export function BodyMedium({ style, children }) {
  return (
    <BodySmall
      style={{
        fontSize: 14,
        ...style,
      }}>
      {children}
    </BodySmall>
  );
}

export function BodyLarge({ style, children }) {
  return (
    <BodyMedium
      style={{
        fontSize: 18,
        fontFamily: "Barlow",
        fontWeight: "bold",
        ...style,
      }}>
      {children}
    </BodyMedium>
  );
}
