import { Container, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import React from "react";
import { Heading2 } from "../../../components/typography";

export default function HomeTryIt() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Container style={{ maxWidth: 1120, backgroundColor: "#000" }}>
      <Grid
        style={{
          marginLeft: "auto",
          marginRight: "auto",

          borderRadius: 24,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          textAlign: "center",

          // backgroundImage: matches ? "url(/images/cta.webp)" : "url(/images/cta.webp)",
        }}>
        <Heading2
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            color: "#fff",
            paddingLeft: matches ? 62 : 40,
            paddingRight: matches ? 62 : 40,
            fontSize: 23,
            padding: 16,
          }}>
          Try it by sending an email to try@emailwebhook.com
        </Heading2>
      </Grid>
    </Container>
  );
}
