import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import React, { Component } from "react";
// import CookieConsent from "react-cookie-consent";
import AlertDialog from "../components/AlertDialog";
import { alertStore } from "../components/AlertDialog/AlertDialog";
import ErrorBoundary from "../components/ErrorBoundary";
import SnackbarHelper, {
  showError,
  snackbarStore,
} from "../components/SnackbarHelper/SnackbarHelper";
import LoadingScreen from "../features/loading/LoadingScreen";
import auth from "../services/firebase/auth";
import Router from "./navigation/Router";
import appStore from "./store/appStore";
import { getViewerId } from "./store/useLocalStorage";

function signOut() {
  return new Promise((resolve, reject) => {
    const currentUser = auth.currentUser;

    if (!currentUser) {
      reject();

      return;
    }

    auth
      .signOut()
      .then(value => {
        resolve(value);
      })
      .catch(reason => {
        reject(reason);
      });
  });
}

class App extends Component {
  logout() {
    appStore.setWorking(true);

    signOut()
      .then(() => snackbarStore.open("Signed out"))
      .then(alertStore.close)
      .catch(reason => showError(reason))
      .finally(() => appStore.reset());
  }

  render() {
    const { ready, working, theme } = appStore;

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />

        <ErrorBoundary>
          {!ready && <LoadingScreen />}

          {ready && (
            <>
              <Router />

              <SnackbarHelper />

              <AlertDialog
                open={alertStore.isOpen}
                contentText="Are you sure?"
                dismissiveAction={
                  <Button color="primary" onClick={alertStore.close}>
                    Cancel
                  </Button>
                }
                confirmingAction={
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={working}
                    onClick={this.logout}>
                    Sign Out
                  </Button>
                }
              />
            </>
          )}
        </ErrorBoundary>
        {/*false && (
          <CookieConsent
            location="bottom"
            cookieName={"accept-all-cookies"}
            buttonText="Accept all cookies"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}>
            Cookie tracking for the best roomview experience. By clicking “Accept all cookies”, you
            agree to the storing of cookies on your device to enhance site navigation, analyze site
            usage, and assist in our marketing efforts.
          </CookieConsent>
        )*/}
      </MuiThemeProvider>
    );
  }

  componentDidMount() {
    const uuid = getViewerId();
    if (!uuid) {
      console.log({ uuid });
    }

    this.onAuthStateChangedObserver = auth.onAuthStateChanged(
      user => {
        // The user is not signed in or doesn’t have a user ID.
        if (!user || !user.uid) {
          appStore.reset();
          return;
        }

        appStore.setUser(user);
        appStore.setReady(true);
      },
      error => {
        appStore.reset();
        showError(error);
      }
    );
  }

  componentWillUnmount() {
    if (this.onAuthStateChangedObserver) {
      this.onAuthStateChangedObserver();
    }
  }
}

export default observer(App);
