import { Container, Grid } from "@material-ui/core";
import React from "react";
import Colors from "../../../components/Colors";
import { BodyMedium, Heading5 } from "../../../components/typography";

function Feature({ title, image, text }) {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={4}
      lg={4}
      style={{ textAlign: "left", border: "0px solid blue" }}>
      <div style={{ textAlign: "center" }}>
        <img
          alt={title}
          src={image}
          style={{ width: 50, marginTop: 24, marginBottom: 10 }}
          width={50}
          height={50}
        />
      </div>
      <Heading5 style={{ margin: 0, color: "#555", textAlign: "center" }}>{title}</Heading5>
      <BodyMedium
        style={{
          color: Colors.Gray.G900,
          padding: 0,
          marginTop: 5,
          marginLeft: "auto",
          marginRight: "auto",
          fontSize: 16,
          marginBottom: 32,
          maxWidth: 280,
        }}>
        {text}
      </BodyMedium>
    </Grid>
  );
}
export default function HomeHow() {
  return (
    <>
      <div id="how" style={{ marginTop: -80, marginBottom: 80 }} />

      <Container style={{ paddingTop: 50, paddingBottom: 50, maxWidth: 1120 }}>
        <Grid container style={{ padding: 20 }}>
          <Feature
            title="What is EmailWebhook?"
            image="images/create.webp"
            text={`It is a service designed to simplify email integration into your business workflow. It allows you to effortlessly capture incoming emails as JSON via a webhook URL, enabling automation and efficient email processing.`}
          />
          <Feature
            title="Why?"
            image="images/share.webp"
            text={`Email is a vital tool for customer-business communication. With EmailWebhook, integrate it into your workflow, leverage AI, and unleash endless possibilities within your app, not just your inbox.`}
          />
          <Feature
            title="How it works?"
            image="images/present.webp"
            text={`Create a webhook by providing a URL to receive emails as JSON. A unique email address will be generated, tied to your webhook. Any emails received at this address will be automatically sent to the webhook for further processing.`}
          />
        </Grid>
      </Container>
    </>
  );
}
