import { action, observable } from "mobx";
import appearance from "../../services/appearance";

const appStore = observable({
  user: null,
  ready: false,
  working: false,
  userData: null,
  theme: appearance.defaultTheme,

  setUser: action(user => {
    appStore.user = user;
    if (user && user.uid) {
      // tracker.setUserID(user.uid);
    }
  }),
  setReady: action(ready => (appStore.ready = ready)),
  setWorking: action(working => (appStore.working = working)),
  setUserData: action(userData => (appStore.userData = userData)),

  reset: action(() => {
    appStore.user = null;
    appStore.ready = true;
    appStore.working = false;
    appStore.userData = null;
  }),
});

export default appStore;
